const data = [
    { id: 1, name: 'Curitiba', address: 'Rua Bom Jesus, 208 | cj. 1904', city: 'Juvevê', zipCode: '80035-010', phone: '(41) 99862.1295'},
    { id: 2, name: 'Florianópolis',address: 'Rua Demétrio Ribeiro, 51 | 505',city: 'Koerich Beiramar Office',zipCode: 'Centro | 88020-700',phone: '(48) 3054.6660'},
    { id: 3, name: 'Passo fundo', address: 'Rua Independência, 800. 4º andar',city: 'Centro',zipCode: '99010-041',phone: '(54) 3311.1428 | (54) 3311.1231'},
    { id: 4, name: 'Porto alegre', address: 'Av. Ipiranga, 40 | 1511',city: 'Trend Offices - Praia de Belas',zipCode: '90160-090',phone: '(51) 3307.2166'},
    { id: 5, name: '', address: '',city: '',zipCode: '',phone: ''},
    { id: 6, name: 'Salvador', address: 'Av. Tancredo Neves, 1632 | 1805 e 1806',city: 'Salvador Trade Center, Torre Sul',zipCode: '41820-000',phone: '(51) 3307.2166'},
    { id: 7, name: 'São Paulo', address: 'Av. Paulista, 1471 | 1110',city: 'Bela Vista',zipCode: '01311-927',phone: '(51) 3307.2166'},
    { id: 8, name: '', address: '',city: '',zipCode: '',phone: ''},
    
];
  
export default [...data]