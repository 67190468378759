<template>
  <v-row>
    <v-col cols="12">
      <h3 class="font-weight-regular mb-3">Filtrar por</h3>

      <v-form @keyup.native.enter="load()">
        <v-row justify="start">
          <v-col cols="12" md="4">
            <v-text-field dense label="Empresa" v-model="dataset.filter.name" outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-select dense :items="dataset.districts" label="Comarca" placeholder="Selecione uma Comarca"
              v-model="dataset.filter.district" item-value="id" item-text="name" outlined hide-details="auto"></v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-select dense :items="dataset.states" label="UF" placeholder="Selecione um UF"
              v-model="dataset.filter.state" item-value="id" item-text="name" outlined hide-details="auto"></v-select>
          </v-col>

          <v-col md="2" class=" text-uppercase text-right">
            <v-btn color="secondary" class="findButton-filter" @click="load()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row justify="space-between" class="pt-10 px-4 mb-2">
        <h3 class="font-weight-bold">Empresa</h3>
        <h3 class="font-weight-bold">Litisconsórcio</h3>
      </v-row>

      <v-list>
        <template v-if="!dataset.loading && dataset.clients.length">
          <a v-for="(item, index) in dataset.clients" :key="index" :href="item.pdfLink"
            @click="navigateToDetails(item.id)" target="_blank" width="100%"
            class="pa-0 d-flex  flex-column align-start list-item-link mb-2  ">
            <v-col class="pa-0">
              <v-divider class="primary"></v-divider>
              <v-list-item class="d-flex align-center">
                <!-- <v-list-item-avatar v-if="item.banner_url">
                  <v-img :src="item.banner_url"></v-img>
                </v-list-item-avatar> -->
                <v-list-item-avatar>
                  <v-avatar :size="40">
                    <img :src="'https://ui-avatars.com/api/?background=7E55AB&color=fff&name=' + item.name" />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase font-weight-medium mediumGray--text">{{
                    item.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </a>
        </template>
        <template v-else-if="!dataset.loading && !dataset.clients.length">
          <v-divider class="primary"></v-divider>
          <div class="pa-0 my-2">
            <span>Nenhuma empresa encontrada</span>
          </div>
        </template>
        <template v-else-if="dataset.loading">
          <v-divider class="primary"></v-divider>
          <div class="pa-0 my-2">
            <span>Buscando...</span>
          </div>
        </template>
        <v-divider class="primary"></v-divider>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import object from '@/common/util/object'
import axios from 'axios'
import statesList from "@/common/data/states-list.data"

export default {
  name: 'FilterComponent',

  props: {
    type: null
  },

  data() {
    return {
      dataset: {
        clients: [],
        districts: [],
        states: [{ id: "", name: "Todos" }, ...statesList],
        filter: {
          name: '',
          district: '',
          state: ''
        },
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['name'],
          sortDesc: [false]
        },
        count: 0,
        loading: false
      }
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      await this.loadDistricts()
      await this.load()
    },

    getInitial(name) {
      return name ? name.charAt(0).toUpperCase() : '';
    },

    async loadDistricts() {
      try {
        const result = await axios.get(`/api/districts`, {
          params: { limit: 100000 }
        })
        this.dataset.districts = [{ id: "", name: "Todas" }, ...result.data.content.data]

      } catch (error) {
      
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }
    },

    async load() {
      if (!this.type) return;

      let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v));
      search = !object.isEmpty(search) ? search : {};
      search.type = this.type;

      this.dataset.loading = true;

      try {
        const result = await axios.get('/api/clients', {
          params: { limit: 10000, search: search }
        });

        const sortedClients = result.data.content.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        this.dataset.clients = sortedClients;
        this.dataset.count = result.data.count;
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      } finally {
        this.dataset.loading = false;
      }
    },


    async navigateToDetails(id) {
      await this.$router.push({ name: 'CasesDetails', params: { id } });
    },
  }
}
</script>

<style scoped >
.findButton-filter {
  margin-right: 0px !important;
  margin-top: 1px !important;
  font-size: 18px !important;
  width: 150px;
  height: 40px !important;
  color: white !important;
  background-color: #009986 !important;
  transition: background-color 0.3s ease;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.findButton:hover {
  background-color: #0077cc !important;
}

.v-list-item-avatar span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
</style>

