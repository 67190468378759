<template>
    <section>
      <div class="limiter-content pa-2 pa-lg-0">
        <div class="padding-block"></div>
  
        <v-row justify="center">
          <v-col lg="12">
            <h1 class="section-page-title">
                <span class="primary--text">informação</span><br><span
                    class="section-page-title-underline darkSecondary--text"><strong>bancária</strong></span>
            </h1>
          </v-col>
        </v-row>
  
        <br />
  
        <div v-html="dataset.description"></div>

        <v-form ref="form" v-if="user" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <!-- <v-card-title class="card-group-title">Dados Bancários</v-card-title> -->
                                <v-card-text>
                                   
                                    <v-row>
                                        <v-col cols="6" >
                                            <v-text-field
                                                dense
                                                label="Nome do banco"
                                                :rules="[$rules.required]"
                                                v-model="dataset.data.bank_name"
                                                outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" >
                                            <v-text-field
                                                dense
                                                label="Número do banco"
                                                :rules="[$rules.required]"
                                                v-model="dataset.data.bank_code"
                                                outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                dense
                                                label="Agência"
                                                :rules="[$rules.required]"
                                                v-model="dataset.data.agency"
                                                outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                     
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                dense
                                                label="Número da conta"
                                                :rules="[$rules.required]"
                                                v-model="dataset.data.account_number"
                                                outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                dense
                                                label="Digito"
                                                v-model="dataset.data.account_digit"
                                                outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>   
                                    </v-row>
                                    <v-row>
                                      
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                dense
                                                :items="dataset.accountType"
                                                label="Tipo de conta"
                                                :rules="[$rules.required]"
                                                v-model="dataset.data.account_type"
                                                item-value="id"
                                                item-text="name"
                                                outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field 
                                                dense id="registration" 
                                                label="CPF/CNPJ" 
                                                v-model="dataset.data.registration" 
                                                required 
                                                @input="formatRegistration()"
                                                :rules="[$rules.required, (dataset.data.registration && dataset.data.registration.length < 13 ? $rules.cpf : $rules.cnpj)]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row>
                                      
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                dense
                                                :items="dataset.pixKeyTypes"
                                                label="Tipo de chave PIX"
                                                v-model="dataset.data.pix_type"
                                                item-value="id"
                                                item-text="name"
                                                outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                      
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                dense
                                                label="Chave PIX"
                                                :rules="[pixKeyValidation(dataset.data.pix_type)]"
                                                v-model="dataset.data.pix_key"
                                                outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                               

                                    <v-row>
                                        <v-col cols="12">
                                            <v-radio-group
                                                v-model="dataset.accountTypeChoice"
                                                row
                                            >
                                                <v-radio label="Conta Própria" :value="true" />
                                                <v-radio label="Conta Procurador" :value="false" />
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    
                                    <template v-if="dataset.accountTypeChoice === false">
                                        <v-card outlined class="pa-4">
                                            <br>
                                            <v-row dense align="center"> 
                                                <v-col cols="11"> 
                                                    <v-select
                                                        v-model="dataset.data.selectedLawyer"
                                                        :items="dataset.lawyers.lawyer"
                                                        item-text="name"
                                                        item-value="id"
                                                        label="Selecione um procurador"
                                                        dense
                                                        no-data-text="Nenhum procurador encontrado"
                                                        outlined
                                                    
                                                        @change="handleLawyerChange"
                                                    ></v-select>
                                                </v-col>
                                        
                                                <v-col cols="1" class="d-flex justify-center mt-n7"> 
                                                    <v-tooltip bottom>
                                                        <template #activator="{ on }">
                                                        <v-btn v-on="on" color="green" @click="openProcuratorModal" dark rounded>
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                        </template>
                                                        <span>Cadastrar novo procurador</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                            <v-row dense>
                                                <v-col cols="12"  v-if="!Array.isArray(dataset.lawyers.lawyer) || !dataset.lawyers.lawyer.length" class="ma-0 pa-0">
                                                    <v-alert type="info" dense>
                                                        Nenhum procurador encontrado.
                                                    </v-alert>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="user">
            <v-col cols="12">
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn v-on="on" color="primary" @click="save()" class='ml-auto' :disabled="dataset.loading">
                            Salvar
                            <v-progress-circular v-if="dataset.loading" indeterminate color="white" size="20"
                            class="ml-2"></v-progress-circular>
                        </v-btn>
                    </template>
                    <span>Salvar dados bancários</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <div class='mt-10' v-if='!user'>
            <h4>Por favor, realize o seu login para ter acesso a esta área</h4>
        </div>

        <v-dialog v-model="procuratorModal" max-width="500px">
            <v-form ref="formNewLawyer" v-model="valid">
                <v-card>
                    <!-- <v-card-title class="text-h6">Cadastrar Procurador</v-card-title> -->
                    <v-card-title class="blue darken-1 white--text pa-3">
                        <v-row align="center" justify="center" class="text-center">
                          <v-col cols="auto" class="d-flex align-center">
                            <span class="text-h6 font-weight-medium">Cadastrar Procurador</span>
                          </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                        <v-card-text>
                            <br>
                           
                            <v-row>
                                <v-col>
                                    <v-text-field dense id="procurator_name" label="Nome procurador" v-model="dataset.data.procurator_name" required
                                        :rules="[$rules.required]" outlined hide-details="auto">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field dense id="procurator_federal_registration" label="Número OAB" v-model="dataset.data.procurator_federal_registration" required
                                        :rules="[$rules.required]" outlined hide-details="auto">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.statesList"
                                        label="OAB UF" v-model="dataset.data.procurator_federal_registration_uf" item-value="name"
                                        item-text="name" :rules="[$rules.required]" outlined hide-details="auto">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field dense id="procurator_email" label="E-mail" v-model="dataset.data.procurator_email" required
                                        :rules="[$rules.required]" outlined hide-details="auto">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col >
                                    <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                                        counter required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                                        hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                                        label="Documento OAB" :clearable="true" @change="onProcuratorSelected($event)" ref="procurator_file">
                                    </v-file-input>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col >
                                    <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon="" outlined
                                        counter required :rules="[$rules.required, $rules.fileSize(4)]" show-size
                                        hint="O arquivo não pode ser superior a 4MB" hide-details="auto"
                                        label="Documento procuração" :clearable="true" @change="onProcurationSelected($event)" ref="procuration_file">
                                    </v-file-input>

                                </v-col>
                            </v-row>
                   
                        </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn color="primary" @click="closeProcuratorModal" class="w-100">Cancelar</v-btn>
                        <v-btn color="primary" @click="saveLawyer()" style="width: 110px" class="ml-3 mr-3" :disabled="dataset.loading">
                            Salvar 
                            <v-progress-circular v-if="dataset.loading" indeterminate color="white" size="20"
                            class="ml-2"></v-progress-circular>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
  
        <v-row>
          <whatsButton/>
        </v-row>
              
        <v-row>
          <topButton/>
        </v-row>
      </div>
    </section>
</template>
  
<script>
    import 'moment/locale/pt-br'
    import { eventHub } from '@/main'
    import axios from 'axios'
    import { cpf, cnpj } from 'cpf-cnpj-validator'
    import statesList from "@/common/data/states-list.data"
    import pixKeyTypes from '@/common/data/pix-key-types.data';
    
    import topButton from '@/components/layout/GoToTopButton.vue';
    import whatsButton from '@/components/layout/WhatsAppButton.vue';
  
    export default {
        name: 'BankInformation',
        data: () => ({
            user: null,
            valid:null,
            procuratorModal: false,
            dataset: {
                accountType: [{ id: 1, name: "Conta corrente" }, { id: 2, name: "Conta poupança" }],
                pixKeyTypes: pixKeyTypes,
                statesList: statesList,
                data: {
                    bank_name: '',
                    bank_code: '',
                    agency: '',
                    account_number: '',
                    account_digit: '',
                    account_type: null,
                    registration: '',
                    pixKeyType: null, 
                    pix_key: '', 
                    selectedLawyer: null,
                },
                lawyers: [],
                accountTypeChoice: null,
                loading: false,
            },
            dataForm: {
                validForm: true,
                addLoading: false,
                updateLoading: false,
                removeLoading: false,
            },
           
            
        }),
        
        components: {
            topButton,
            whatsButton,
        },
    
        created() {
            if (this.$root.user) {
                this.user = this.$root.user;
                this.startLoad();
            }
                
        },
    
        methods: {
            openRegister() {
                eventHub.$emit('DIALOG-REGISTER', true)
            },

            async startLoad(){
                await this.loadText();
                await this.loadData();
                await this.loadLawyer();
            },

            closeProcuratorModal() {
                this.procuratorModal = false;
            },

            openProcuratorModal() {
                this.procuratorModal = true;
            },

            onAccountTypeChange(value) {
                
                if (this.dataset.accountTypeChoice === value) {
                    this.dataset.accountTypeChoice = null; 
                } else {
                    this.dataset.accountTypeChoice = value; 
                }
            },
            
            formatCpfCnpj(value) {
                return value.length == 14 ? cnpj.format(value) : cpf.format(value);
            },

            formatRegistration() {
                this.dataset.data.registration = this.formatCpfCnpj(this.dataset.data.registration);
            },

            handleLawyerChange(value) { 
                this.dataset.data.selectedLawyer = value
            },

            onProcurationSelected(procuration_file) {
                this.dataset.data.procuration_file = procuration_file;
            },

            onProcuratorSelected(procurator_file) {
                this.dataset.data.procurator_file = procurator_file;
            },

            pixKeyValidation(pixKeyType) {
                return value => {
                    if (pixKeyType === 1) {
                        return this.isValidCpf(value) || 'CPF inválido para chave PIX.';
                    } else if (pixKeyType === 2) {
                        return this.isValidCnpj(value) || 'CNPJ inválido para chave PIX.';
                        // } else if (pixKeyType === 3) {
                        // return this.isValidPhone(value) || 'Telefone inválido para chave PIX.';
                    } else if (pixKeyType === 4) {
                        return this.isValidEmail(value) || 'E-mail inválido para chave PIX.';
                    } else if (pixKeyType === 5) {
                        return value.length === 32 || 'Chave aleatória deve ter 32 caracteres.';
                    }
                    return true;
                };
            },

            isValidCpf(value) {
                return value.length === 11 || 'Chave CPF deve ter 11 digitos.';
            },

            isValidCnpj(value) {
                return value.length === 14 || 'Chave CNPJ deve ter 14 digitos.';
            },

            isValidEmail(value) {
                return value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'E-mail inválido.';
            },
    

            async loadText() {

                this.dataset.loading = true;

                try {
                    const result = await axios.get(`/api/dynamic-infos/keys`, {
                        params: { keys: 'bank-information-description' }
                    })
        
                    this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : ''; 
        
                } catch (error) {
                    this.$dialog.notify.error(this.$codes(error), {
                        position: 'top-right',
                        timeout: 2000
                    })
                }finally {
                    this.dataset.loading = false;
                }
            },


            async loadData() {

                this.dataset.loading = true;

                try {
                    const result = await axios.get(`/api/users/bank-information`);

                    if (result.data && result.data.content && Array.isArray(result.data.content.data) && result.data.content.data.length > 0) {
                        this.dataset.data = result.data.content.data[0];

                        const registration = this.dataset.data.registration;
                        if (registration) {
                            this.dataset.data.registration = registration.length < 14 ? cpf.format(registration) : cnpj.format(registration);
                        }

                        if (this.dataset.data.lawyer_id == null) {
                            this.dataset.accountTypeChoice = true;
                        }

                    }

                } catch (error) {
                    this.$dialog.notify.error(this.$codes(error), {
                        position: 'top-right',
                        timeout: 2000
                    });
                } finally {
                    this.dataset.loading = false;
                }
            },

            async loadLawyer() {

                this.dataset.loading = true;

                try {
                    const result = await axios.get(`/api/users/lawyer`);

                    const lawyers = result.data.content.data.map(item => item.lawyer);

                    this.$set(this.dataset.lawyers, 'lawyer', lawyers);

                    if (Array.isArray(this.dataset.lawyers.lawyer) && this.dataset.lawyers.lawyer.length > 0 && this.dataset.data.lawyer_id) {
                        
                        const selectedLawyer = this.dataset.lawyers.lawyer.find(
                            lawyer => lawyer.id === this.dataset.data.lawyer_id
                        );
            
                        if (selectedLawyer) {
                            this.dataset.data.selectedLawyer = selectedLawyer.id;
                            this.dataset.accountTypeChoice = false
                        }
                    }

                } catch (error) {
                    this.$dialog.notify.error(this.$codes(error), {
                        position: 'top-right',
                        timeout: 2000
                    });
                } finally {
                    this.dataset.loading = false;
                }
            },

            goBack() {
                this.dataset.data = {};
                eventHub.$emit('DIALOG-REGISTER', false)
            },

            async saveLawyer(){
            
                if (!this.$refs.formNewLawyer.validate()) return;

                const proceedAction = await this.$dialog.confirm({
                    text: 'Deseja salvar as informações do procurador?',
                    title: 'Salvar dados procurador',
                    actions: {
                        false: 'Cancelar',
                        true: 'Salvar'
                    }
                })

                if (!proceedAction) return;

                this.dataset.loading = true;
                
                const payload = this.buildPayloadProcurator();
                
                try {
                    const response = await axios.post('/api/users/lawyer', payload);

                    this.$dialog.notify.info("Informações do procurador salvas com sucesso!", { position: "top-right", timeout: 3000 });

         
                } catch (error) {
                    this.$dialog.notify.error(this.$codes(error), {
                        position: 'top-right',
                        timeout: 2000
                    })
                } finally {
                    this.dataset.loading = false;
                }

                this.closeProcuratorModal();
                this.loadLawyer();
            },


            async save() {
                if (!this.$refs.form.validate()) return;

                const proceedAction = await this.$dialog.confirm({
                    text: 'Deseja salvar as informações bancárias?',
                    title: 'Salvar informações',
                    actions: {
                        false: 'Cancelar',
                        true: 'Salvar'
                    }
                })

                if (!proceedAction) return;

                this.dataset.loading = true;
                
                const payload = this.buildPayload();
                
                try {
                    const response = await axios.post('/api/users/bank-information', payload);

                    this.$dialog.notify.info("Informações bancárias salvas com sucesso!", { position: "top-right", timeout: 3000 });

                    await this.loadData();
                    await this.loadLawyer();

                } catch (error) {
                    this.$dialog.notify.error(this.$codes(error), {
                        position: 'top-right',
                        timeout: 2000
                    })
                } finally {
                    this.dataset.loading = false;
                }

            },


            buildPayload() {
                const formData = new FormData()
                    
                    if (this.dataset.data.bank_name) {
                        formData.append('bank_name', this.dataset.data.bank_name);
                    }
                
                    if (this.dataset.data.registration) {
                        formData.append('registration', this.dataset.data.registration.replace(/\D/g, ''));
                    }

                    if (this.dataset.data.bank_code) {
                        formData.append('bank_code', this.dataset.data.bank_code);
                    }

                    if (this.dataset.data.agency) {
                        formData.append('agency', this.dataset.data.agency);
                    }

                    if (this.dataset.data.account_number) {
                        formData.append('account_number', this.dataset.data.account_number);
                    }

                    if (this.dataset.data.account_digit) {
                        formData.append('account_digit', this.dataset.data.account_digit);
                    }

                    if (this.dataset.data.account_type) {
                        formData.append('account_type', this.dataset.data.account_type);
                    }

                    if (this.dataset.data.pix_type) {
                        formData.append('pix_type', this.dataset.data.pix_type);
                    }

                    if (this.dataset.data.pix_key) {
                        formData.append('pix_key', this.dataset.data.pix_key);
                    }

                    if (this.dataset.data.selectedLawyer && this.dataset.accountTypeChoice === false) {
                        formData.append('lawyer_id', this.dataset.data.selectedLawyer);   
                    }

                return formData;
            },


            buildPayloadProcurator() {
                const formData = new FormData()
                    
                    if (this.dataset.data.procurator_name) {
                        formData.append('procurator_name', this.dataset.data.procurator_name);
                    }

                    if (this.dataset.data.procurator_email) {
                        formData.append('procurator_email', this.dataset.data.procurator_email);
                    }

                    if (this.dataset.data.procurator_federal_registration) {
                        formData.append('procurator_federal_registration', this.dataset.data.procurator_federal_registration);
                    }

                    if (this.dataset.data.procurator_federal_registration_uf) {
                        formData.append('procurator_federal_registration_uf', this.dataset.data.procurator_federal_registration_uf);
                    }

                    if (this.dataset.data.procurator_file){
                    formData.append('procurator_file', this.dataset.data.procurator_file) 
                    }

                    if (this.dataset.data.procuration_file){
                    formData.append('procuration_file', this.dataset.data.procuration_file) 
                    }

                return formData;
            }
        }
    }
  
</script>
  
<style lang="scss">
.title-page {
    font-family: 'Raleway';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}
  
.underline::after {
    content: '';
    display: block;
    margin: 5px 0;
    height: 3px;
    width: 180px;
    background: var(--v-primary-base);
}
  
.title-h1 {
    font-size: 40px;
    color: var(--v-mediumGray-base);
}
  
.text-p {
    font-size: 20px;
    font-family: 'Raleway';
}
  
.sub-title {
    color: rgb(75, 72, 72);
    font-family: 'Raleway';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
}
  
</style>
  