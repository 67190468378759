<template>
  <section>

    <div class="limiter-content pa-2 pa-lg-0">

      <div class="padding-block"></div>

      <v-row v-if="dataset.data" justify="center">
        <v-col>
          <h1 class="underline font-title font-weight-bold">
            {{ dataset.data.name }}
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col>
          <v-row align="center" justify="space-between">
            <v-col lg="7">
              <p><strong>Processo:</strong> {{ dataset.data.process_number || 'N/A' }}</p>
              <p><strong>Especialista Responsável:</strong> {{ dataset.data.employee ? dataset.data.employee.name :
                'N/A' }} </p>
              <p v-if="dataset.data.judgment_at && dataset.data.judgment_at !== '0000-00-00 00:00:00'"><strong>Ajuizamento:</strong>
                {{ $moment(dataset.data.judgment_at).utc(false).format('DD/MM/YYYY') }}
              </p>
              <!-- <p><strong>Termo Legal:</strong>
                {{ dataset.data.legal_term && dataset.data.legal_term !== '0000-00-00 00:00:00' ?
                  $moment(dataset.data.legal_term).utc(false).format('DD/MM/YYYY') :
                'N/A' }}
              </p> -->
              <p v-if="dataset.data.legal_term && dataset.data.legal_term !== '0000-00-00 00:00:00'"><strong>Termo
                  Legal:</strong>
                {{ $moment(dataset.data.legal_term).utc(false).format('DD/MM/YYYY') }}
              </p>
              <p v-if="dataset.data.deferment_at && dataset.data.deferment_at !== '0000-00-00 00:00:00'">
                <strong>Deferimento:</strong>
                {{ $moment(dataset.data.deferment_at).utc(false).format('DD/MM/YYYY') }}
              </p>
              <p v-if="dataset.data.insolvency_at && dataset.data.insolvency_at !== '0000-00-00 00:00:00'">
                <strong>Declaração da Insolvência:</strong>
                {{ $moment(dataset.data.insolvency_at).utc(false).format('DD/MM/YYYY') }}
              </p>
              <p v-if="dataset.data.liquidation_at && dataset.data.liquidation_at !== '0000-00-00 00:00:00'">
                <strong>Decretação da Quebra/Liquidação:</strong>
                {{ $moment(dataset.data.liquidation_at).utc(false).format('DD/MM/YYYY') }}
              </p>
              <p><strong>Vara:</strong> {{ dataset.data.local || 'N/A' }}</p>
              <p><strong>Comarca:</strong> {{ dataset.data.district ? dataset.data.district.name : 'N/A' }}</p>
            </v-col>

            <v-col lg="4">
              <v-img :src="dataset.data.logo_url"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col>
          <div v-html="dataset.data.description"></div>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col>
          <v-tabs fixed-tabs>
            <v-tab v-for="(itemType, indexType) in dataset.clientDocTypes" :key="indexType">{{ itemType.name }}</v-tab>

            <v-tab-item v-for="(itemType, indexType) in dataset.clientDocTypes" :key="indexType" class="mt-10">
              <v-list class="gray ">
                <template v-if="itemType.documents && itemType.documents.length">
                  <a v-for="( item, index ) in itemType.documents" :key="index" :href="item.doc_url" target="_blank"
                    class="d-flex align-center list-item-link mb-2 white">
                    <v-list-item class="d-flex align-center">
                      <v-list-item-avatar>
                        <v-img src="@/assets/logo-bej-small.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-medium mediumGray--text">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </a>
                </template>
                <template v-else>
                  <span class="px-10">Nenhum documento adicionado nesta aba</span>
                </template>
              </v-list>
            </v-tab-item>

          </v-tabs>
        </v-col>
      </v-row>

      <v-dialog v-if="dataset.data.banner_url" v-model="dialog" max-width="600" hide-overlay
        @click:outside="closeDialog = false">
        <v-card>
          <v-img :src="dataset.data.banner_url"></v-img>
          <v-card-actions>
            <v-btn @click="closeDialog" color="primary">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <whatsButton />
      </v-row>

      <v-row>
        <topButton />
      </v-row>

    </div>

  </section>
</template>

<script>
import 'moment/locale/pt-br';
import axios from "axios";
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

import clientDocTypes from "@/common/data/client-doc-types.data"

export default {
  name: 'CasesDetails',
  components: {
    topButton,
    whatsButton,
  },

  data() {
    return {
      dataset: {
        data: {},
        clientDocTypes: clientDocTypes,
      },
      dialog: false,
    }
  },

  created() {
    this.dialog = true;
  },

  mounted() {
    window.scrollTo(0, 0)
    this.load();
  },

  methods: {

    async load() {
      try {
        const result = await axios.get(`/api/clients/${this.$route.params.id}`);
        this.dataset.data = result.data.content;

        for (let item of this.dataset.clientDocTypes) {
          let docs = this.dataset.data.documents && Array.isArray(this.dataset.data.documents) ? this.dataset.data.documents.filter((x) => x.type === item.id) : [];

          item.documents = docs;

          docs.sort((a, b) => a.order - b.order);
        }

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }
    },

    closeDialog() {
      this.dialog = false;
    }
  }
}
</script>

<style>
.list-item-link {
  text-decoration: none;
  font-family: "Raleway" !important;
}

.v-tabs {
  margin-bottom: 0 !important;
  border-bottom: none !important;
}</style>